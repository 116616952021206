import React, { useState, useEffect } from "react";
import {
  fetchAttemptQuestionsData,
  isExamSubmittedByHead,
  fetchExamLanguages,
  submitApi,
  submitWarning,
  saveOptions,
  fetchQuestionsData,
  fetchProfileApi,
} from "./api";
import {
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorImage from "../../images/404Error.jpeg";
import internetIssue from "../../images/internetissue.jpeg";

import server_Error from "../../images/server_Error.jpeg";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
  },
  profileSection: {
    width: "18%",
    height: "95vh",
    padding: "1rem 0.8rem",
    boxShadow: "0px 8px 15px #D7DAF2",
    zIndex: "1200",
  },
  profilesectioninner: {
    display: "flex",
  },
  imagecontainer: {
    width: " 40%",
    display: "flex",
    background: "#E0E0E2",
    borderRadius: "10px",
  },
  profilephoto: {
    aspectRatio: "1 / 1",
    borderRadius: "10px",
  },
  learnerinfo: {
    marginLeft: "0.5rem",
    "& .MuiTypography-body1": {
      color: "#000",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
      TextTrack: "wrap",
    },
    "& .MuiTypography-body2": {
      color: "#000",
      fontFamily: "Satoshi",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: "500",
      textTransform: "capitalize",
    },
  },
  questionpallatemain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  timer: {
    width: "80%",
    background: "#4565B7",
    borderRadius: "10px",
    padding: "0.7rem",
    margin: "0.5rem 0",
    textAlign: "center",
    "& .MuiTypography-h4": {
      color: "#FFF",
      fontFamily: "Playfair Display",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "800",
      textTransform: "capitalize",
    },
  },
  numberbox: {
    background: "#F0F2FF",
    width: "80%",
    padding: "0.4rem",
    borderRadius: "6px",
  },
  numberboxhead: {
    background: "#4565B7",
    borderRadius: "6px 6px 0px 0px",
    padding: "0.7rem",
    "& .MuiTypography-h4": {
      color: "#FFF",
      fontFamily: "Playfair Display",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: "800",
      textTransform: "capitalize",
    },
  },
  questionNumberBoxmain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "40vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#4565B7",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#39476B",
    },
  },
  questionNumberBoxmaininner: {
    width: "95%",
  },
  questionNumberBox: {
    minWidth: "28px !important",
    height: "28px !important",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    padding: "0",
    margin: "0.3rem",
    backgroundColor: "#D7DAF2",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    "&.selected": {
      backgroundColor: "#4565B7",
      color: "white",
      fontWeight: "bold",
    },
    "&.answered": {
      backgroundColor: "#04A10B",
      color: "white",
      fontWeight: "bold",
    },
    "&.marked": {
      backgroundColor: "yellow",
      color: "black",
      fontWeight: "bold",
    },
    "&.not_attempted": {
      backgroundColor: "#F26809",
      color: "white",
      fontWeight: "bold",
    },
    "&.not_viewed": {
      backgroundColor: "#FF0000",
      color: "white",
      fontWeight: "bold",
    },
    "&.marked_no_answer": {
      background: "Purple",
      color: "white",
      fontWeight: "bold",
    },
  },
  markedNoAnswer: {
    background: "Purple",
    color: "white",
    fontWeight: "bold",
  },
  exam: {
    width: "80%",
  },
  examNameContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#4565B7",
    color: "white",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    "& .MuiTypography-body1": {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.875rem",
      fontStyle: "normal",
      fontWeight: "700",
      textTransform: "capitalize",
    },
    "& .MuiOutlinedInput-root": {
      background: "white",
      margin: "0.5rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 30px 10.5px 14px",
    },
  },
  questionsContainer: {
    "& .MuiTypography-h6": {
      borderTop: "1px solid #797979",
      padding: "0.5rem 1rem",
      borderBottom: "1px solid #797979",
      background: "#D7DAF2",
      color: "#000",
      fontFamily: "'Playfair Display', serif !important",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "uppercase",
    },
    "& .MuiTypography-h5": {
      color: "#303030",
      fontFamily: "'Jost', sans-serif",
      fontSize: "1.25rem",
      margin: "1rem 0.5rem ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  },
  sectionPalette: {
    padding: "1rem",
  },
  selectedQuestion: {
    width: "125px",
    margin: "0 0.5rem",
    padding: "0.5rem",
    background: "#4565B7",
    borderRadius: "6px",
    color: "#FFF",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
    border: "1px solid  #4565B7",
    boxShadow: "none",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    "&:hover": {
      background: "#4565B7",
    },
  },
  unselectedQuestion: {
    width: "125px",
    margin: "0 0.5rem",
    padding: "0.5rem",
    background: "#FFF",
    border: "1px solid  #303030",
    borderRadius: "6px",
    color: "#000",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      background: "#4565B7",
      color: "#FFF",
      border: "1px solid  #4565B7",
    },
  },
  btncontainer: {
    display: "flex",
    width: "75%",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "10px",
  },
  bluebtn: {
    background: "#4565B7",
    border: "1px solid  #4565B7",
    margin: "0 1rem",
    color: "#FFF",
    "&:hover": {
      background: "#4565B7",
      border: "1px solid  #4565B7",
      color: "#FFF",
    },
  },
  transparentbtn: {
    background: "#FFF",
    border: "1px solid  #303030",
    margin: "0 1rem",
    color: "#000",
    "&:hover": {
      background: "#4565B7",
      border: "1px solid  #4565B7",
      color: "#FFF",
    },
  },
  questionsContainerinner: {
    height: "68vh",
    marginTop: "1rem",
    overflow: "auto",
    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },
  questiontext: {
    color: "#303030",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.9rem",
    marginLeft: "2rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",

    "& p": {
      margin: "0rem 1rem",
    },
  },
  options: {
    color: "#727272",
    fontFamily: "'Jost', sans-serif",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    "& li": {
      margin: "1rem 0.5rem ",
      listStyle: "upper-latin",
      "&::marker": {
        verticalAlign: "middle",
      },
      "& label": {
        display: "flex",
        "& span": {
          "& p": {
            margin: "0",
          },
        },
      },
    },
  },
  p1: {
    padding: "1rem",
  },
  finalpopup: {
    padding: "1rem 1rem 0 1rem",
    "& .MuiTypography-h6": {
      color: "#263238",
      fontFamily: "Satoshi",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "700",
      textTransform: "capitalize",
    },
  },
  bgwhite: {
    // height: "40vh",
    background: "white",
    border: "1px solid #D7DAF2",
    borderRadius: "6px",
  },
  legend: {
    width: "100%",
    marginTop: "0.5rem",
    display: "flex",
    flexWrap: "wrap",
  },
  legendinner: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-body1": {
      color: "#727272",
      fontSize: "0.9rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
    },
    "& span": {
      margin: "0.2rem",
      minWidth: "15px",
      minHeight: "15px",
    },
  },
  legendinner2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "0.1rem",
    "& .MuiTypography-body1": {
      color: "#727272",
      fontSize: "0.9rem",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "400",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
    },
    "& span": {
      margin: "0.2rem",
      minWidth: "15px",
      minHeight: "15px",
    },
  },
  attempted: {
    background: "#04A10B",
  },
  notanswered: {
    background: "#F26809",
  },
  markedanswer: {
    background: "yellow",
  },
  markednotanswer: {
    background: "Purple",
  },
  notviewed: {
    background: "#D7DAF2",
  },
  current: {
    backgroundColor: "#4565B7",
  },
});

const Test = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const selectLanguageByProps = location.state?.selectLanguage;
  const [sections, setSections] = useState([]);
  const [examData, setExamData] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [dob, setDob] = useState("");
  const [examDuration, setExamDuration] = useState("");
  const [examName, setExamName] = useState("");
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [finalSubmitChecked, setFinalSubmitChecked] = useState(false);
  const [questionStatus, setQuestionStatus] = useState({});
  const [totalQuestions, setTotalQuestions] = useState("");
  const [attemptedWithAnswered, setAttemptedWithAnswered] = useState("");
  const [attemptedWithoutAnswered, setAttemptedWithoutAnswered] = useState("");
  const [markedWithAnswered, setMarkedWithAnswered] = useState("");
  const [markedWithotAnswered, setMarkedWithotAnswered] = useState("");
  const [notViewedQ, setNotViewedQ] = useState("");
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(true);
  const [submitError, setSubmitError] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("english");
  const [languages, setLanguages] = useState([]);
  const examId = sessionStorage.getItem("examId");
  const [remainingSeconds1, setRemainingSeconds1] = useState(0);
  const [instantResultState, setInstantResultState] = useState("no");
  const [isError, setIsError] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [showAutoNavigateMessage, setShowAutoNavigateMessage] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [countdownTime, setCountdownTime] = useState(10);
  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
  const [countdownByHead, setCountdownByHead] = useState(0);
  const [showServerErrorDialog, setShowServerErrorDialog] = useState(false);
  const [attemptedQstatus, setAttemptedQstatus] = useState([]);

  // const loginTime = sessionStorage.getItem("loginTime");

  const [loginTime, setLoginTime] = useState(null);

  const fetchAttemptedQstatus = async (examId) => {
    try {
      const responseData = await fetchAttemptQuestionsData(examId);

      if (responseData.attempted_questions) {
        setAttemptedQstatus(responseData.attempted_questions);

        setLoginTime(responseData.exam_start_time);
      } else {
        setAttemptedQstatus([]);
      }
    } catch (error) {
      setIsError(true);
      console.error("Error fetching attempted questions:", error);
    }
  };

  useEffect(() => {
    if (location.pathname === "/test") {
      const interval = setInterval(() => {
        const examId = sessionStorage.getItem("examId");
        if (examId) {
          fetchAttemptedQstatus(examId);
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [examId, location.pathname]);

  const processAttemptedQuestions = (attemptedQuestions) => {
    const newStatuses = {};
    attemptedQuestions.forEach((question) => {
      let status;
      switch (question.attempt_status) {
        case "attempted":
          status = "answered";
          break;
        case "marked":
          status = question.option_id ? "marked" : "marked_no_answer";
          break;
        case "not answered":
          status = "not_attempted";
          break;
        default:
          status = "not_viewed";
      }
      newStatuses[question.question_id] = status;
    });
    setQuestionStatus(newStatuses);
  };

  useEffect(() => {
    if (attemptedQstatus.length > 0) {
      processAttemptedQuestions(attemptedQstatus);
    }
  }, [attemptedQstatus]);

  useEffect(() => {
    const storedInstantResultState =
      sessionStorage.getItem("examInstantResult");
    if (storedInstantResultState) {
      setInstantResultState(storedInstantResultState);
    }
  }, []);

  useEffect(() => {
    function handleOffline() {
      setIsOffline(true);
    }
    function handleOnline() {
      setIsOffline(false);
    }
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const handleCopy = (e) => {
    e.preventDefault();
    toast.warning("You cannot copy text from this page!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handlefetchExamLanguages = async (examId) => {
    try {
      const languageData = await fetchExamLanguages(examId);
      if (languageData && languageData.length > 0) {
        setLanguages(
          languageData.map((lang) => ({
            label:
              lang.language.charAt(0).toUpperCase() + lang.language.slice(1),
            value: lang.language,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    const examId = sessionStorage.getItem("examId");
    handlefetchExamLanguages(examId);
  }, []);

  const handleChangeLanguage = (event) => {
    setSelectLanguage(event.target.value);
  };

  const submitWarningAPI = async (examId) => {
    try {
      const submitWarningData = await submitWarning(examId);

      setTotalQuestions(submitWarningData.totalQuestions);
      setNotViewedQ(submitWarningData.notViwed);
      setAttemptedWithAnswered(submitWarningData.attemptedWithAnswered);
      setAttemptedWithoutAnswered(submitWarningData.attemptedWithoutAnswered);
      setMarkedWithAnswered(submitWarningData.markedWithAnswered);
      setMarkedWithotAnswered(submitWarningData.markedWithotAnswered);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    setSelectLanguage(selectLanguageByProps);
  }, []);

  const [remainingTime, setRemainingTime] = useState(3600); // 1 hour

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (loginTime) {
      const currentTime = new Date().getTime();
      const elapsedTime1 = currentTime - loginTime;
      setRemainingSeconds1(Math.floor(elapsedTime1 / 1000));
    }
  }, [loginTime]);

  // time exprire
  useEffect(() => {
    if (remainingTime === 0 && !isTimeUp) {
      setIsTimeUp(true);

      const examId = sessionStorage.getItem("examId");
      if (examId) {
        submitWarningAPI(examId);
      }
    }
  }, [remainingTime, isTimeUp]);

  useEffect(() => {
    if (isTimeUp) {
      setShowSubmitModal(true);
      setShowAutoNavigateMessage(true);

      const timer = setInterval(() => {
        setCountdownTime((prevTime) => prevTime - 1);
      }, 1000);

      const timeout = setTimeout(() => {
        clearInterval(timer);
        handleFinalSubmit();
      }, 4000);

      return () => {
        clearTimeout(timeout);
        clearInterval(timer);
      };
    }
  }, [isTimeUp]);

  // timer formate
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // user profile api
  const fetchProfileData = async () => {
    try {
      const candidateData = await fetchProfileApi();
      setProfilePic(candidateData.image_url);
      setName(candidateData.full_name);
      setEmail(candidateData.email);
      setNumber(candidateData.phone);

      // Convert the incoming DOB format to "7 Dec 2003" format
      const rawDob = candidateData.learner_information.dob;
      const dobDate = new Date(rawDob);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day = dobDate.getDate();
      const monthIndex = dobDate.getMonth();
      const year = dobDate.getFullYear();

      const formattedDob = `${day} ${monthNames[monthIndex]} ${year}`;

      setDob(formattedDob);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setShowServerErrorDialog(true);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchQuestions = async (examId) => {
    try {
      const questionsData = await fetchQuestionsData(examId);

      if (!questionsData) {
        setIsError(true);
      } else {
        setExamData(questionsData);
        setSections(questionsData.subjects);
        setExamName(questionsData.exam_name);
        const storedExamDuration = questionsData.duration;
        setExamDuration(storedExamDuration);
        setRemainingTime(storedExamDuration * 60 - remainingSeconds1);
      }
    } catch (error) {
      setIsError(true);
      setShowServerErrorDialog(true);
      console.error("Error fetching questions:", error);
    }
  };
  useEffect(() => {
    const examId = sessionStorage.getItem("examId");
    if (examId) {
      fetchQuestions(examId);
    }
  }, []);

  useEffect(() => {
    if (activeQuestionId) {
      // Scroll to active question
      const activeQuestionElement = document.getElementById(
        `question-${activeQuestionId}`
      );
      if (activeQuestionElement) {
        activeQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [activeQuestionId]);

  const handleSectionSelect = (sectionIndex, event) => {
    event.stopPropagation();

    // Simply set the section index and reset the question index to 0
    setSelectedSectionIndex(sectionIndex);
    setSelectedQuestionIndex(0);

    // Select the first question of the newly selected section
    if (sections[sectionIndex] && sections[sectionIndex].questions.length > 0) {
      setSelectedQuestion(sections[sectionIndex].questions[0]);
    }
  };

  const handleOptionSelect = async (option) => {
    setSelectedOption(option);

    // Store the selected option in local storage
    // localStorage.setItem(
    //   `selectedOptionId_forSectionId_${sections[selectedSectionIndex].id}_ofQuestionId_${selectedQuestion.id}`,
    //   option
    // );

    // // Prepare data for API call
    // const examId = sessionStorage.getItem("examId");
    // const exam_id = examId;
    // const subject_id = sections[selectedSectionIndex].id;
    // const question_id = selectedQuestion.id;
    // const option_id = option;
    // const attempt_status = "marked";

    // try {
    //   // Call the API function
    //   const apiResponse = await saveOptions({
    //     exam_id,
    //     subject_id,
    //     question_id,
    //     option_id,
    //     attempt_status,
    //   });

    //   if (apiResponse.success) {
    //     console.log("Option answered successfully");
    //   } else {
    //     console.log(apiResponse.message || "Failed to save option");
    //   }
    // } catch (error) {
    //   console.error("API error:", error);
    // }
  };

  useEffect(() => {
    if (
      sections.length > 0 &&
      sections[selectedSectionIndex].questions.length > 0
    ) {
      const selectedQuestion = sections[selectedSectionIndex].questions[0];
      setSelectedQuestion(selectedQuestion);
      setSelectedQuestionIndex(0);
    }
  }, [sections, selectedSectionIndex]);

  const isOptionSelected = selectedOption !== null;

  const handleSaveAndNext = async () => {
    const examId = sessionStorage.getItem("examId");
    const exam_id = examId;
    const subject_id = sections[selectedSectionIndex].id;
    const question_id = selectedQuestion.id;
    const option_id = selectedOption || "";

    let attempt_status = "";

    if (option_id) {
      attempt_status = "attempted";
    } else {
      const currentStatus = questionStatus[selectedQuestion.id];
      if (currentStatus === "marked") {
        attempt_status = "marked";
      }
    }

    try {
      const apiResponse = await saveOptions({
        exam_id,
        subject_id,
        question_id,
        option_id,
        attempt_status,
      });

      if (apiResponse.success) {
        console.log("Option answered successfully");
      } else {
        console.log(apiResponse.message || "Failed to save option");
        setShowServerErrorDialog(true);
      }
    } catch (error) {
      console.error("API error:", error);
      setShowServerErrorDialog(true);
    }

    moveToNextQuestion();
  };

  // const handleSaveAndPrevious = async () => {
  //   const examId = sessionStorage.getItem("examId");
  //   const exam_id = examId;
  //   const subject_id = sections[selectedSectionIndex].id;
  //   const question_id = selectedQuestion.id;
  //   const option_id = selectedOption || "";

  //   let attempt_status = "";

  //   if (option_id) {
  //     attempt_status = "attempted";
  //   } else {
  //     attempt_status = "";
  //   }

  //   try {
  //     const apiResponse = await saveOptions({
  //       exam_id,
  //       subject_id,
  //       question_id,
  //       option_id,
  //       attempt_status,
  //     });

  //     if (apiResponse.success) {
  //       console.log("Option answered successfully");
  //     } else {
  //       console.log(apiResponse.message || "Failed to save option");
  //     }
  //   } catch (error) {
  //     console.error("API error:", error);
  //   }

  //   handleBack();

  //   if (option_id === (null || "")) {
  //     updateQuestionStatus(selectedQuestion.id, "not_attempted");
  //   } else {
  //     updateQuestionStatus(selectedQuestion.id, "answered");
  //   }
  // };

  const handleMarkForReview = async () => {
    const examId = sessionStorage.getItem("examId");
    const exam_id = examId;
    const subject_id = sections[selectedSectionIndex].id;
    const question_id = selectedQuestion.id;
    const option_id = selectedOption || "";
    const attempt_status = "marked";

    try {
      const apiResponse = await saveOptions({
        exam_id,
        subject_id,
        question_id,
        option_id,
        attempt_status,
      });

      if (apiResponse.success) {
        console.log("Option marked for review successfully");
      } else {
        console.log(apiResponse.message || "Failed to mark option for review");
        setShowServerErrorDialog(true);
      }
    } catch (error) {
      console.error("API error:", error);
      setShowServerErrorDialog(true);
    }

    moveToNextQuestion();
  };

  const handleNext = async () => {
    moveToNextQuestion();
    const examId = sessionStorage.getItem("examId");

    const exam_id = examId;
    const subject_id = sections[selectedSectionIndex].id;
    const question_id = selectedQuestion.id;
    const option_id = "";
    const attempt_status = "";

    try {
      const apiResponse = await saveOptions({
        exam_id,
        subject_id,
        question_id,
        option_id,
        attempt_status,
      });

      if (apiResponse.success) {
        console.log("success");
      } else {
        console.log(apiResponse.message || "failed to send data");
        setShowServerErrorDialog(true);
      }
    } catch (error) {
      console.error("API error:", error);
      // Handle the error as needed
      setShowServerErrorDialog(true);
    }
  };

  const clearResponse = () => {
    setSelectedOption(null);
  };

  const moveToNextQuestion = () => {
    const currentSection = sections[selectedSectionIndex];
    const totalQuestionsInCurrentSection = currentSection.questions.length;

    // Check if there is a next question in the current section
    if (selectedQuestionIndex < totalQuestionsInCurrentSection - 1) {
      // Move to the next question in the current section
      const nextQuestionIndex = selectedQuestionIndex + 1;
      const nextQuestion = currentSection.questions[nextQuestionIndex];

      setSelectedQuestion(nextQuestion);
      setSelectedQuestionIndex(nextQuestionIndex);

      // Update selected option based on attempted questions status
      const attemptedQuestion = attemptedQstatus.find(
        (q) => q.question_id === String(nextQuestion.id)
      );
      if (attemptedQuestion) {
        setSelectedOption(attemptedQuestion.option_id);
      } else {
        setSelectedOption(null);
      }
    } else {
      // Check if there is a next section
      if (selectedSectionIndex < sections.length - 1) {
        // Move to the first question of the next section
        const nextSectionIndex = selectedSectionIndex + 1;
        const nextSection = sections[nextSectionIndex];
        const nextQuestion = nextSection.questions[0];

        setSelectedSectionIndex(nextSectionIndex);
        setSelectedQuestion(nextQuestion);
        setSelectedQuestionIndex(0);

        // Update selected option based on attempted questions status
        const attemptedQuestion = attemptedQstatus.find(
          (q) => q.question_id === String(nextQuestion.id)
        );
        if (attemptedQuestion) {
          setSelectedOption(attemptedQuestion.option_id);
        } else {
          setSelectedOption(null);
        }
      } else {
        console.log("No more questions or sections.");
      }
    }
  };

  // Helper function to check if it's the last question in the question palette
  const isLastQuestionInPalette = () => {
    return (
      selectedSectionIndex === sections.length - 1 &&
      selectedQuestionIndex ===
        sections[selectedSectionIndex].questions.length - 1
    );
  };

  const handleOpenSubmitModal = () => {
    setShowSubmitModal(true);

    const examId = sessionStorage.getItem("examId");
    if (examId) {
      submitWarningAPI(examId);
    }
  };

  const handleCloseSubmitModal = () => {
    setShowSubmitModal(false);
  };

  const handleFinalSubmitCheck = () => {
    setFinalSubmitChecked(!finalSubmitChecked);
  };

  const isExamSubmittedByHeadCheck = async () => {
    try {
      const response = await isExamSubmittedByHead(examId);
      if (response.success) {
        setShowSubmissionPopup(true);
        setInstantResultState(sessionStorage.getItem("examInstantResult"));
        submitWarningAPI(examId);
        let timer = 10;
        const countdownInterval = setInterval(() => {
          setCountdownByHead(timer);
          if (timer === 1) {
            setInstantResultState(sessionStorage.getItem("examInstantResult"));
            clearInterval(countdownInterval);
            setShowSubmissionPopup(false);
            navigateBasedOnStateBySubmissionOfHead(response.examResult);
          }
          timer--;
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting exam:", error);
      const errorMessage = error.response
        ? error.response.data.message
        : "Unknown error";
      setSubmitError(errorMessage);
    }
  };

  useEffect(() => {
    if (location.pathname === "/test") {
      const interval = setInterval(() => {
        isExamSubmittedByHeadCheck();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [examId, location.pathname]);

  const navigateBasedOnStateBySubmissionOfHead = (examResult) => {
    const currentInstantResultState =
      sessionStorage.getItem("examInstantResult");

    if (currentInstantResultState === "no") {
      navigate("/thankyou");
    } else if (currentInstantResultState === "without_answer") {
      navigate("/examscore", {
        state: { examResult: examResult },
      });
    } else if (currentInstantResultState === "with_answer") {
      navigate("/examscore", {
        state: { examResult: examResult },
      });
    }
  };

  const handleFinalSubmit = async () => {
    try {
      const response = await submitApi({ examId });
      if (response.success) {
        handleCloseSubmitModal();

        if (instantResultState === "no") {
          navigate("/thankyou");
        } else if (instantResultState === "without_answer") {
          navigate("/examscore", {
            state: { examResult: response.examResult },
          });
          // navigate("/examscore");
        } else if (instantResultState === "with_answer") {
          navigate("/examscore", {
            state: { examResult: response.examResult },
          });
          // navigate("/examscore");
        }
      }
    } catch (error) {
      // <img src={errorImage} alt="Error" />
      console.error("Error submitting exam:", error);
      const errorMessage = error.response
        ? error.response.data.message
        : "Unknown error";
      setSubmitError(errorMessage);
    }
  };

  const getAbsoluteQuestionNumber = (sectionIndex, questionIndex) => {
    let questionNumber = 1; // Start counting from 1
    for (let i = 0; i < sectionIndex; i++) {
      questionNumber += sections[i].questions.length;
    }
    questionNumber += questionIndex;
    return questionNumber;
  };

  const handleBack = () => {
    if (selectedQuestionIndex > 0) {
      // Move to the previous question in the same section
      const previousQuestionIndex = selectedQuestionIndex - 1;
      const previousQuestion =
        sections[selectedSectionIndex].questions[previousQuestionIndex];

      setSelectedQuestion(previousQuestion);
      setSelectedQuestionIndex(previousQuestionIndex);

      // Update selected option based on attempted questions status
      const attemptedQuestion = attemptedQstatus.find(
        (q) => q.question_id === String(previousQuestion.id)
      );
      if (attemptedQuestion) {
        setSelectedOption(attemptedQuestion.option_id);
      } else {
        setSelectedOption(null);
      }
    } else if (selectedSectionIndex > 0) {
      // Move to the last question of the previous section
      const previousSectionIndex = selectedSectionIndex - 1;
      const previousSection = sections[previousSectionIndex];
      const lastQuestionIndex = previousSection.questions.length - 1;
      const previousQuestion = previousSection.questions[lastQuestionIndex];

      setSelectedSectionIndex(previousSectionIndex);
      setSelectedQuestion(previousQuestion);
      setSelectedQuestionIndex(lastQuestionIndex);

      // Update selected option based on attempted questions status
      const attemptedQuestion = attemptedQstatus.find(
        (q) => q.question_id === String(previousQuestion.id)
      );
      if (attemptedQuestion) {
        setSelectedOption(attemptedQuestion.option_id);
      } else {
        setSelectedOption(null);
      }
    } else {
      console.log("You are at the first question of the exam.");
    }
  };

  useEffect(() => {
    setIsBackButtonDisabled(
      selectedSectionIndex === 0 && selectedQuestionIndex === 0
    );
  }, [selectedSectionIndex, selectedQuestionIndex]);

  const selectedQuestionContent = () => {
    if (selectLanguage === "hindi" && selectedQuestion.question_hi) {
      return { __html: selectedQuestion.question_hi };
    } else {
      return { __html: selectedQuestion.question_en };
    }
  };

  const getOptionContent = (option) => {
    if (selectLanguage === "hindi" && option.option_hi) {
      return { __html: option.option_hi };
    } else {
      return { __html: option.option_en };
    }
  };

  const flattenQuestions = sections.flatMap((section, sectionIndex) =>
    section.questions.map((question, questionIndex) => {
      const questionNumber = getAbsoluteQuestionNumber(
        sectionIndex,
        questionIndex
      );
      return {
        id: question.id,
        number: questionNumber,
      };
    })
  );

  const [questionRefs, setQuestionRefs] = useState([]);

  useEffect(() => {
    // Create a ref for each question
    setQuestionRefs(
      sections.flatMap((section) =>
        section.questions.map(() => React.createRef())
      )
    );
  }, [sections]);
  useEffect(() => {
    if (selectedQuestion) {
      const questionIndex = flattenQuestions.findIndex(
        (q) => q.id === selectedQuestion.id
      );
      const ref = questionRefs[questionIndex];
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [selectedQuestion, questionRefs]);

  const handleQuestionSelectByQuestionId = (questionId, event) => {
    event.stopPropagation();

    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      const foundQuestionIndex = sections[sectionIndex].questions.findIndex(
        (question) => question.id === questionId
      );

      if (foundQuestionIndex !== -1) {
        setSelectedSectionIndex(sectionIndex);
        setSelectedQuestionIndex(foundQuestionIndex);

        const selectedQuestion =
          sections[sectionIndex].questions[foundQuestionIndex];
        setSelectedQuestion(selectedQuestion);
        setActiveQuestionId(questionId);
        // Update selected option based on attempted questions status
        const attemptedQuestion = attemptedQstatus.find(
          (q) => q.question_id === String(questionId)
        );
        if (attemptedQuestion) {
          setSelectedOption(attemptedQuestion.option_id);
        } else {
          setSelectedOption(null);
        }

        const paletteQuestionElement = document.getElementById(
          `question-${questionId}`
        );
        if (paletteQuestionElement) {
          paletteQuestionElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }

        break;
      }
    }
  };

  useEffect(() => {
    if (
      sections.length > 0 &&
      sections[selectedSectionIndex].questions.length > 0
    ) {
      setSelectedQuestion(
        sections[selectedSectionIndex].questions[selectedQuestionIndex]
      );
      setSelectedQuestionIndex(selectedQuestionIndex);
    }
  }, [sections, selectedQuestionIndex, selectedSectionIndex]);

  return (
    <>
      <ToastContainer />
      {isOffline ? (
        <div style={{ textAlign: "center", marginTop: "2%" }}>
          <img src={internetIssue} alt="Internet Issue" />
        </div>
      ) : isError ? (
        <div style={{ textAlign: "center" }}>
          <img src={errorImage} alt="Error" />
          <p>Sorry, an error occurred while fetching the questions.</p>
        </div>
      ) : (
        <div className={classes.mainContainer} onCopy={handleCopy}>
          {/* Profile Section Details */}
          <div className={classes.profileSection}>
            <div className={classes.profilesectioninner}>
              <div className={classes.imagecontainer}>
                <img
                  className={classes.profilephoto}
                  src={profilePic}
                  alt="Profile"
                  width="100%"
                />
              </div>
              <div className={classes.learnerinfo}>
                <Typography variant="body1">{name}</Typography>
                {/* <Typography variant="body2"></Typography> */}
                <Typography variant="body1">{number}</Typography>
                {/* <Typography variant="body2"></Typography> */}
                {/* <Typography variant="body1">Date of Birth :</Typography>
                <Typography variant="body2">{dob}</Typography> */}
                {/* <Typography variant="body1">Email: {email}</Typography> */}
              </div>
            </div>

            <div className={classes.questionpallatemain}>
              <div className={classes.timer}>
                <Typography variant="h4" color="primary">
                  Time Left: {formatTime(remainingTime)}
                </Typography>
              </div>
              <div className={classes.numberbox}>
                {sections.length > 0 && (
                  <div className={classes.bgwhite}>
                    <div className={classes.numberboxhead}>
                      <Typography
                        variant="h4"
                        className={classes.questionLabel}
                      >
                        Questions
                      </Typography>
                    </div>
                    <div className={classes.questionNumberBoxmain}>
                      <div className={classes.questionNumberBoxmaininner}>
                        {flattenQuestions.map(({ id, number }, index) => {
                          return (
                            <Button
                              key={id}
                              id={`question-${id}`}
                              ref={questionRefs[index]} // Assign the ref here
                              onClick={(event) =>
                                handleQuestionSelectByQuestionId(id, event)
                              }
                              className={`${classes.questionNumberBox} ${
                                selectedQuestion && selectedQuestion.id === id
                                  ? "selected"
                                  : questionStatus[id] || ""
                              }`}
                            >
                              {number}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={classes.legend}>
                <div className={classes.legendinner2}>
                  <span className={classes.current}></span>{" "}
                  <Typography>Current Question</Typography>
                </div>
                <div className={classes.legendinner2}>
                  <span className={classes.attempted}></span>{" "}
                  <Typography>Answered</Typography>
                </div>
                <div className={classes.legendinner2}>
                  <span className={classes.notanswered}></span>{" "}
                  <Typography>Not Answered</Typography>
                </div>
                <div className={classes.legendinner2}>
                  <span className={classes.markedanswer}></span>{" "}
                  <Typography>Marked & Answered</Typography>
                </div>
                <div className={classes.legendinner2}>
                  <span className={classes.markednotanswer}></span>{" "}
                  <Typography>Marked & Not Answered</Typography>
                </div>
                <div className={classes.legendinner2}>
                  <span className={classes.notviewed}></span>{" "}
                  <Typography>Not viewed</Typography>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.exam}>
            <div>
              <div className={classes.examNameContainer}>
                <div></div>
                <Typography className={classes.examName}>{examName}</Typography>
                <Select
                  className={classes.langoption}
                  variant="outlined"
                  onChange={handleChangeLanguage}
                  value={selectLanguage}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Select language</em>
                  </MenuItem>
                  {languages.map((lang) => (
                    <MenuItem key={lang.value} value={lang.value}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {/* Right Side: Questions and Options */}
            <div className={classes.questionsContainer}>
              {/* // Left Side: Question Palette */}
              <div className={classes.sectionPalette}>
                {sections.map((section, sectionIndex) => (
                  <Button
                    key={sectionIndex}
                    // variant="h6"
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleSectionSelect(sectionIndex, event)
                    }
                    className={
                      sectionIndex === selectedSectionIndex
                        ? classes.selectedQuestion
                        : classes.unselectedQuestion
                    }
                  >
                    {section.subject_name}
                  </Button>
                ))}
              </div>
              {selectedQuestion ? (
                <div>
                  <Typography variant="h6">
                    {" "}
                    Question Number:{" "}
                    {getAbsoluteQuestionNumber(
                      selectedSectionIndex,
                      selectedQuestionIndex
                    )}{" "}
                  </Typography>
                  <div className={classes.questionsContainerinner}>
                    <span
                      className={classes.questiontext}
                      dangerouslySetInnerHTML={selectedQuestionContent()}
                    />
                    {/* <p>
                  Description:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: selectedQuestion.description,
                    }}
                  />
                </p> */}

                    {/* Options Label */}
                    <Typography variant="h5">Options:</Typography>

                    <ul className={classes.options}>
                      {selectedQuestion.options.map((option, index) => (
                        <li key={index}>
                          <label>
                            <input
                              type="radio"
                              value={option.id}
                              checked={Number(selectedOption) === option.id}
                              onChange={() => handleOptionSelect(option.id)}
                            />
                            <span
                              dangerouslySetInnerHTML={getOptionContent(option)}
                            />
                          </label>
                        </li>
                      ))}
                    </ul>

                    <div className={classes.btncontainer}>
                      <div>
                        {/* Add the Back Button */}
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.bluebtn}
                          onClick={handleBack}
                          disabled={isBackButtonDisabled}
                        >
                          Previous
                        </Button>

                        {/* Mark for Review Button */}
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.transparentbtn}
                          onClick={handleMarkForReview}
                          // disabled={!isOptionSelected}
                        >
                          Mark & Review
                        </Button>
                      </div>

                      {/* Next Button */}
                      {/* <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNext}
                  disabled={isLastQuestionInPalette()}
                >
                  Next
                </Button> */}

                      <div>
                        {/* Clear Response Button */}
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.transparentbtn}
                          onClick={clearResponse}
                        >
                          Clear Response
                        </Button>
                        {/* Save and Next / Submit Button */}
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.transparentbtn}
                          onClick={() => {
                            if (isLastQuestionInPalette()) {
                              handleSaveAndNext()
                                .then(() => {
                                  handleOpenSubmitModal();
                                })
                                .catch(() => {
                                  console.error(
                                    "Error in saving the last question"
                                  );
                                  // Handle any errors if needed
                                });
                            } else {
                              if (isOptionSelected) {
                                handleSaveAndNext();
                              } else {
                                handleNext();
                              }
                            }
                          }}
                        >
                          {isLastQuestionInPalette() ? "Submit" : "Save & Next"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Their are no questions available in this exam.</p>
              )}
            </div>
          </div>

          {showSubmissionPopup && (
            <Dialog open={showSubmissionPopup}>
              <DialogTitle>
                Your exam has been submitted by the head authority !
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div className={classes.p1}>
                    <Typography variant="body1">
                      Total Questions: {totalQuestions}
                    </Typography>
                    <Typography variant="body1">
                      Total Answered: {attemptedWithAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Marked with answered: {markedWithAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Marked without answered: {markedWithotAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Not Answered: {attemptedWithoutAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Not Viewed: {notViewedQ}
                    </Typography>
                    Navigating to the end page in {countdownByHead} seconds...
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          )}

          <Dialog
            open={showServerErrorDialog}
            onClose={() => setShowServerErrorDialog(false)}
          >
            <DialogTitle>Server Error</DialogTitle>
            <DialogContent>
              <img
                src={server_Error}
                alt="Server Error"
                style={{ width: "100%", marginBottom: 10 }}
              />
              <DialogContentText>
                For checking server, click on "Mark For Review" or "Save & Next"
                buttons. If you do not get this popup message, it means your
                server is back stronger.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowServerErrorDialog(false)}>
                Try Again
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for Final Submission and Time's Up */}
          <Dialog
            open={showSubmitModal || isTimeUp}
            onClose={handleCloseSubmitModal}
          >
            <DialogTitle className={classes.finalpopup}>
              {isTimeUp ? "Time's Up!" : "Final Submission"}
            </DialogTitle>

            {isTimeUp ? (
              <DialogContent>
                <DialogContentText>
                  <div className={classes.p1}>
                    <Typography variant="body1">
                      Total Questions: {totalQuestions}
                    </Typography>
                    <Typography variant="body1">
                      Total Answered: {attemptedWithAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Marked with answered: {markedWithAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Marked without answered: {markedWithotAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Not Answered: {attemptedWithoutAnswered}
                    </Typography>
                    <Typography variant="body1">
                      Not Viewed: {notViewedQ}
                    </Typography>
                  </div>
                  <Typography color="red" variant="body1">
                    {submitError}
                  </Typography>
                  {showAutoNavigateMessage && (
                    <Typography variant="body2" style={{ marginTop: "1rem" }}>
                      You will be navigated in {countdownTime} seconds to the
                      end page. Your exam has been submitted.
                    </Typography>
                  )}
                </DialogContentText>
              </DialogContent>
            ) : (
              <div className={classes.p1}>
                <Typography variant="body1">
                  Total Questions: {totalQuestions}
                </Typography>
                <Typography variant="body1">
                  {" "}
                  Total Answered: {attemptedWithAnswered}
                </Typography>
                <Typography variant="body1">
                  Marked with answered: {markedWithAnswered}
                </Typography>
                <Typography variant="body1">
                  Marked without answered: {markedWithotAnswered}
                </Typography>
                <Typography variant="body1">
                  Not Answered: {attemptedWithoutAnswered}
                </Typography>
                <Typography variant="body1">
                  Not Viewed: {notViewedQ}
                </Typography>

                <Typography color="red" variant="body1">
                  {submitError}
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={finalSubmitChecked}
                      onChange={handleFinalSubmitCheck}
                      color="primary"
                    />
                  }
                  label="I confirm that I want to finally submit the exam."
                />
              </div>
            )}

            <DialogActions>
              <Button
                onClick={handleCloseSubmitModal}
                color="primary"
                disabled={isTimeUp}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleFinalSubmit()}
                color="primary"
                disabled={!finalSubmitChecked}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Test;
