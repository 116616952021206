import {
  Button,
  Card,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { ReactComponent as PhoneIcon } from "../../images/candidateicon.svg";
import { ReactComponent as PinIcon } from "../../images/pinicon.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { loginApi } from "./api";




const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "1.5rem",
    padding: "1rem",
  },
  alignCenter: {
    alignSelf: "center",
  },
  container: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    height: "100vh",
    background: "#fff",
    '& .MuiSvgIcon-root':{
      fill:"#E0E0E0",
      cursor:'pointer',
    },
    "& .MuiTypography-h5": {
      color: "#000",
      textAlign: "center",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
    },
    "& .MuiFormControl-root": {
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiButton-root": {
      background: "#4565B7",
      color: "#FFF",
      fontSize: "0.9375rem",
      fontWeight: "700",
      textTransform: "capitalize",
    },
    "& .MuiCard-root": {
      padding: "20px 70px",
    },
    "& .MuiPaper-elevation1": {
      width: "25%",
      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
    },
  },
  inputhead: {
    marginTop: "1rem",
  },
  
}));

function LoginMain({ onLogin }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [exam_pin, setExam_pin] = useState("");
  const [formError, setFormError] = useState({});
  const [error, setError] = useState(" Please Enter Credentials");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleSubmit = async () => {

    const validationErrors = validateForm();
    setFormError(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {
      const apiResponse = await loginApi({ username, password, exam_pin });

      if (apiResponse.success) {
        onLogin();
        navigate("/instructions");
       
      } else {
  
        setError(apiResponse.message || "Login failed");
      }
    } else {
   console.log(error)
    }
  };

  const validateForm = () => {
    var errors = {};

    if (username.length === 0) {
      errors.email = "required";
      toast.error("enter valid phone number")
    }

    if (password.length === 0) {
      errors.password = "required";
      toast.error("enter valid password")
    }
    if (exam_pin.length === 0) {
      errors.password = "required";
      toast.error("enter valid exam pin")
    }

    return errors;
  };

  return (
    <>
        <ToastContainer />
      <div className={classes.container}>
        <Card>
          <FormControl className={classes.root}>
            <Typography variant="body1" color="error" align="center">
              {error}
            </Typography>
            <Typography variant="h5" align="center">
              Login
            </Typography>
            <Typography className={classes.inputhead} variant="h6">
              Phone Number
            </Typography>
            <TextField
              placeholder="987643210"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Typography className={classes.inputhead} variant="h6">
              Exam Password
            </Typography>
            <TextField
              placeholder="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment onClick={togglePasswordVisibility} position="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
            />
            <Typography className={classes.inputhead} variant="h6">
              Exam Pin
            </Typography>
            <TextField
  placeholder="Exam Pin"
  type="password"
  variant="outlined"
  value={exam_pin}
  onChange={(e) => {
    setExam_pin(e.target.value);
    // Additional logic here if needed
  }}
  inputProps={{ maxLength: 4 }}
  error={exam_pin.length !== 4 && exam_pin.length > 0}
  helperText={
    (exam_pin.length !== 4 && exam_pin.length > 0) ? "Exam PIN must be exactly 4 digits" : ""
  }
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <PinIcon />
      </InputAdornment>
    ),
  }}
/>

            <Button onClick={handleSubmit} className={classes.margin} fullWidth>
              Submit
            </Button>
          </FormControl>
        </Card>
      </div>
    </>
  );
}

export default LoginMain;